.main-layout {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

html,
body {
  background: #19162a !important;
}

.main-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid rgba(68, 71, 80, 1);
  background: rgba(27, 28, 32, 1);
  height: 100%;
  color: #7d7e93;
  padding: 15px 20px;
  z-index: 1000;
}

.main-menu-row {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
}

@media screen and (max-width: 767px) {
  .main-menu-row {
    height: auto;
  }
}

.main-menu-row .position-banner {
  margin-top: auto;
}

.main-section {
  /* width: calc(100% - 280px); */
  overflow-y: auto;
  background-size: 100% 100%;
  margin-left: auto;
}

.main-section.pd-0 {
  padding-bottom: 0;
}

.main-menu-row .row-logo {
  padding-right: 20px;
}

.main-menu-row .row-logo img {
  /* width: 180px; */
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .main-menu {
    margin-top: 20px;
  }
}

.main-menu .list-menu li img {
  width: 24px;
  /* margin-right: 10px; */
  filter: grayscale(0.9);
}
.main-menu .list-menu .active img {
  filter: unset;
}

.main-menu .title-menu {
  color: rgba(226, 232, 255, 0.7);
  font-family: Onest;
  font-size: 14px;
  font-family: "AnekLatin-Regular";
  font-weight: 600;
  line-height: 24px;
  padding-top: 15px;
  border-top: 1px solid rgba(226, 232, 255, 0.2);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.menu-banner{
  border-radius: 16px;
}
.main-menu .title-menu div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.menu-land {
  cursor: pointer;
}

.main-menu .list-menu a {
  display: flex;
  align-items: center;
  color: #7d7e93;
  font-family: "AnekLatin-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  transition: 0.3s ease;
  position: relative;
}

.main-menu .list-menu a {
  padding: 10px;
}
.main-menu .list-menu li{
  margin-bottom: 5px;
}
.main-menu .list-menu li a {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.main-menu .list-menu li a:focus {
  outline: none;
}

.main-menu .list-menu .active {
  background: rgba(0, 0, 0, 0.4);
  color: rgba(0, 133, 255, 1);
}



.main-menu-row .main-banner {
  margin-top: 20px;
  margin-bottom: 10px;
}

.main-price .box-price {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #272634;
  background: #434750;
  padding: 8px;
  width: 100%;
}

.main-price .box-price .img img {
  width: 24px;
  display: block;
  margin-right: 10px;
}

.main-price {
  margin-bottom: 10px;
  width: 100%;
}

.main-price .box-price .price {
  color: #fff;
  font-family: Onest;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
}

.main-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.main-social .item img {
  width: 35px;
}

.main-social .item {
  cursor: pointer;
}

.main-header {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(226, 232, 255, 0.1);
  background: rgba(27, 28, 32, 1);
  padding: 15px 35px;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 280px);
  z-index: 100;
}

.main-header .left {
  width: 50%;
}

.main-header .right {
  width: 50%;
}

.main-header .left .input-row .f-control {
  width: 100%;
  border-radius: 8px;
  border: 0.75px solid rgba(226, 232, 255, 0.1);
  background: #12101d;
  height: 40px;
  padding: 0 15px 0 40px;
  color: #fff;
}

.main-header .left .input-row {
  position: relative;
}

.main-header .left .input-row .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.main-header .left .input-row .icon img {
  width: 20px;
}

.f-control:focus {
  outline: none;
}

.main-header .right .btn-deposit {
  border-radius: 8px;
  border: 0.7px solid var(--12, #26253c);
  padding: 8px 25px;
  background: #26253c;
  color: #fff;
  text-align: center;
  text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 40px;
}

.main-header .right .btn-deposit:hover {
  border-radius: 8px;
  border: 0.7px solid var(--12, #9e7aff);
  background: #26253c;
  padding: 8px 25px;
  background: var(
    --12,
    linear-gradient(97deg, #9e7aff 0%, #fe8bbb 53.13%, #ffbd7a 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 40px;
}

.btn:focus {
  outline: none;
}

.main-header .right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.main-header .right .item {
  margin-left: 10px;
}

.main-header .right .item .noti img {
  width: 42px;
  display: block;
}

.main-header .right .btn-deposit img {
  width: 24px;
}

.main-layout.active .main-header {
  display: none;
}

.main-layout.active .main-sidebar {
  display: none;
}

.main-layout.active .main-section {
  width: 100%;
  background-size: cover;
  overflow-x: hidden;
  min-height: 100vh;
}

@media screen and (max-width: 1023px) {
  .main-layout.active .main-section {
    overflow-x: hidden;
  }
}

.pro {
  display: flex;
  gap: 5px;
  padding: 8px 15px !important;
}

.img-logout {
  width: 22px;
  height: 22px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding-bottom: 8px;
  padding-top: 8px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  border-radius: 6px;
  background: rgba(27, 28, 32, 1);
}

.ant-dropdown-menu {
  background-color: #26253c !important;
}

.text-profile {
  color: #fff !important;
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(159, 162, 185, 0.57);
}

.main-menu .list-menu li img:nth-child(2) {
  display: none;
}

.main-menu .list-menu li.active img:nth-child(2) {
  display: block;
}

.main-menu .list-menu li.active img:nth-child(1) {
  display: none;
}

.modal .ant-modal-content {
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 255, 0.1);
  background: rgba(27, 28, 32, 1);
}

.modal .ant-modal-title {
  background: rgba(27, 28, 32, 1);
  color: #fff;
  text-align: center;
  font-family: Onest;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  padding-top: 30px;
}

.modal .ant-modal-close-icon {
  border-radius: 6px;
  background: #26253c;
  padding: 5px;
}

.modal .ant-modal-close-icon svg {
  fill: #fff;
}

.ant-modal-footer {
  display: none !important;
}

button:focus {
  outline: none;
}

.modal-authen .content-row {
  color: rgba(226, 232, 255, 0.6);
  text-align: center;
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding-bottom: 40px;
}

.modal-authen .content-row.res {
  color: rgba(226, 232, 255, 0.6);
  text-align: left;
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding-bottom: 40px;
}

.main-change {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.content-change .img-change img {
  width: 100px;
  margin: 0 auto;
}

.content-change .img-change {
  margin-bottom: 30px;
}

.content-change .title {
  color: #fff;

  text-align: center;
  font-family: Onest;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}

.content-change .desc {
  color: rgba(226, 232, 255, 0.7);
  text-align: center;
  font-family: Onest;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}

.content-change .item-input .f-control {
  width: 100%;
  border-radius: 8px;
  border: 0.75px solid rgba(226, 232, 255, 0.1);
  background: #12101d;
  height: 40px;
  padding: 0 15px 0 15px;
  color: #fff;
}

.content-change .item-input .txt {
  color: #fff;
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-bottom: 10px;
}

.content-change .item-input {
  margin-bottom: 20px;
}

.content-change .item-input .input-row img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  cursor: pointer;
}

.content-change .item-input .input-row {
  position: relative;
}

.btn-confirm-change {
  width: 100%;
  height: 40px;
  border-radius: var(--parameters-radius-md, 8px);

  background: #8762ed;

  box-shadow: 0px -3px 0px 0px rgba(6, 6, 31, 0.22) inset,
    0px 1px 0px 0px rgba(226, 232, 255, 0.8) inset,
    0px 4px 20px 0px rgba(2, 2, 16, 0.29);
  color: #fff;

  text-align: center;
  text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
  font-family: Onest;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .main-change {
    padding: 15px;
  }

  .content-change .title {
    font-size: 27px;
  }

  .content-change .desc {
    font-size: 17px;
  }
}

@media (min-width: 1300px) and (max-width: 1600px) {
  .main-menu-row .position-banner {
    position: relative;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .main-sidebar.mobile {
    width: 50% !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    height: 100% !important;
    left: inherit !important;
  }

  .background {
    position: relative;
  }

  .background::before {
    content: "";
    background: #000000a1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
}

@media screen and (max-width: 768px) {
  .main-menu .list-menu li.active:before {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .main-section {
    width: 100%;
    padding-bottom: 120px;
    margin-top: 67px;
    min-height: 100vh;
  }

  .main-layout.active .main-section {
    margin-top: 0;
  }

  .main-header.mobile .left .logo img {
    width: 120px;
  }

  .main-header.mobile {
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    .main-header.mobile {
      padding: 17px 10px;
    }
  }

  .main-header.mobile .right {
    width: 60%;
  }

  .main-header.mobile .left {
    width: 40%;
  }

  .main-header.mobile .right .item img {
    width: 36px;
  }

  .main-header.mobile .right .btn-deposit {
    font-size: 15px;
    padding: 8px 9px !important;
    height: 36px;
    width: 36px;
  }

  .main-header.mobile .right .btn-deposit img {
    width: 20px;
  }

  .main-header.mobile .right .btn-deposit:hover {
    font-size: 15px;
    padding: 5px 12px;
    height: 36px;
  }

  .main-sidebar.mobile {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    overflow-y: auto;
  }

  .icon-remove {
    position: absolute;
    right: 15px;
    top: 23px;
    z-index: 99999;
  }

  .icon-remove img {
    width: 36px;
  }

  .main-menu-row .position-banner {
    position: relative;
  }

  button:focus {
    outline: none;
  }

  .btn-login-m {
    border-radius: var(--parameters-radius-md, 8px);
    padding: 10px 15px;
    background: #26253c;
    color: #fff;
    text-align: center;
    text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
    font-family: Onest;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-sign-m {
    border-radius: var(--parameters-radius-md, 8px);
    padding: 10px 15px;
    border-radius: var(--parameters-radius-md, 8px);

    background: linear-gradient(
      85deg,
      #9e7aff -10.04%,
      #fe8bbb 46.64%,
      #eb9c4c 103.33%,
      #f8eac3 160%
    );

    box-shadow: 0px -3px 0px 0px rgba(6, 6, 31, 0.22) inset,
      0px 1px 0px 0px rgba(226, 232, 255, 0.8) inset,
      0px 4px 20px 0px rgba(2, 2, 16, 0.29);
    color: #fff;
    text-align: center;
    text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
    font-family: Onest;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    width: 100%;
    margin-bottom: 20px;
  }
}

.text-profile p {
  color: #7d7e93;
  font-family: "Onest";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-profile span {
  color: #fff;
  font-family: "Onest";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.btn-confirm-change.dis {
  background: rgba(226, 232, 255, 0.7);
}

.btn-confirm-change.dis:hover {
  cursor: not-allowed;
}
